import React from 'react';
import { HashRouter as Router, Link,} from 'react-router-dom';

class MyNavBar extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <nav>
            <span className="link1"><Link to="/components/Home">Home</Link></span>
            <span className="link1"><Link to="/components/Workout">Workout</Link></span>
            <span className="link1"><Link to="/components/PostWorkout">PostWorkout</Link></span>
          </nav>
        </div>
      </Router>
    );
  }
}

export default MyNavBar;