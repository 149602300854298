
import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify'
import moment from 'moment';
import '../App.css';
import PostWorkout from './PostWorkout'

function Workout() {
    let yearMonthDate = GetCurrentYearMonth()
    const [startYearMonth, setStartYearMonth] = useState(yearMonthDate)
    const [searchStartYearMonth, setSearchStartYearMonth] = useState(yearMonthDate)
    const [loading, setLoading] = useState(true)
    const [workouts, setWorkouts] = useState([])
    const [dateTime, setDateTime] = useState('')
    const [level, setLevel] = useState('')
    const [type, setType] = useState('')
    const [distance, setDistance] = useState('')
    const [timeMinutes, setTimeMinutes] = useState('')
    const [id, setId] = useState('')
    const [monthlyDistance, setMonthlyDistance] = useState([])
    const [yearDistance2020, setYearDistance2020] = useState(0)

    function GetDate(props){
        let inputArray = props.inputString.split("-")
        let epochDate = Number(inputArray[1])
        let returnDate = moment(epochDate).format('YYYY-MM-DD HH:mm')
        return(returnDate)
    }

    function GetCurrentYearMonth(){
        let currentDate = new Date(); //current date
        let returnDate = moment(currentDate).format('YYYY-MM')
        return(returnDate)
    }

    useEffect(() => {
            async function getWorkouts() {
                //let epochTime = moment('2020-11', "YYYY-MM-DD HH:mm").valueOf();
                let epochTimeStart = moment(startYearMonth, "YYYY-MM-DD").valueOf();
                let epochTimeEnd = moment('2025-01', "YYYY-MM").valueOf();
                console.log("EPOCHTIMESTART",epochTimeStart)
                console.log("EPOCHTIMEEND",epochTimeEnd)
                let beginSK = "orbitaltrainer-" + epochTimeStart
                let endSK = "orbitaltrainer-" + epochTimeEnd
                let myInit = {
                    body:{
                    "Action": 'getWorkouts',
                    "user": 'mark',
                    "beginSK": beginSK,
                    "endSK": endSK,
                    "level": 5
                    }
                }
                const apiData = await API.post ('staffordvilleapi', '/workouts', myInit)
                console.log("RESPONSE",apiData)
                setWorkouts(apiData)
            }
            getWorkouts()
        },[startYearMonth])
    
    let totalDistance = 0
    return(
    <div className="content">
        <button className="butt1" type="button" onClick={() => setStartYearMonth(searchStartYearMonth)} >Get From Date</button>
        <input type="text" value={searchStartYearMonth} onChange={event => setSearchStartYearMonth(event.target.value)} />
    <div className="voice1">
        <h1>Workouts</h1>  
    <table className="tcell1">
        <tbody className="tcell1">
        <tr><td className="tcell1">Date</td><td className="tcell1">Type</td><td className="tcell1">Level</td><td className="tcell1">TimeMinutes</td><td className="tcell1">Distance</td></tr>
        {workouts.map((workout, index) => {
            totalDistance = totalDistance + Number(workout.distance)
            return(
            <tr>
                <td><GetDate inputString={workout.SK} /></td>
                <td>{workout.type}</td>
                <td>{workout.level}</td>
                <td>{workout.timeMinutes}</td>
                <td>{workout.distance}</td>
            </tr>
            )         
       })}
        <tr><td>Totals:</td><td></td><td></td><td></td><td>{totalDistance}</td></tr>
        </tbody>
    </table>
    <PostWorkout />
    </div>
    </div>
    )

}

export default Workout