import React from "react"
import staffordville4 from '../assets/staffordville4.png';

function Header() {
    return (
        <div>
            <div className="divLeft">
                <img src={staffordville4} alt="Logo" className="logo" />
            </div>
            <div className="divHeader1">
                <h1>Staffordville</h1>
            </div>
            <div className="divClear"></div>
            
        </div>
    )
}

export default Header