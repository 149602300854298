import React from 'react';

export default class Test extends React.Component{

    render(){
        return <div className="content">
        <div className="voice1">
        <h2>Welcome to the Stafford family website:</h2>
        <p>The purpose of this site is to give my family and friends a secure place to
          share information, pictures, videos, and applcations without having to use
          some outside service like Facebook that does "who know's what" with the information. 
          I mean, why should I give my information to Facebook for free so they can make a fortune
          off of it? But, enough of my ranting.
        </p>
 
        <p>I hope my family and friends will find this site useful and entertaining.</p>
        <p>Thank you,</p>
        <p>Mark</p>
        <p>.....</p>
        </div>
        </div>
    }
}

