import React from 'react';

import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import MyNavBar from './components/MyNavBar';
import Header from './components/Header';
import Home from './components/Home';
import Workout from './components/Workout';
import PostWorkout from './components/PostWorkout';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';

import '@aws-amplify/ui/dist/style.css';
Amplify.configure(awsconfig);

const MyTheme = {
  navButton: { 'backgroundColor': 'transparent', 'color': '#c6ebc6'},
  navBar: { 'backgroundColor': 'transparent', 'borderColor': 'transparent', 'float': 'right'},
  navItem: { 'backgroundColor': 'transparent', 'color': 'white', 'display': 'none',},
  nav: { 'backgroundColor': 'transparent', 'padding': 0, 'float': 'right'},
  navRight: { 'backgroundColor': 'transparent', 'marginTop': 0 }
}

function App() {
  return (
    <div>
      <Header />
      <MyNavBar />  
        <Router>
          <div className="link1">
            <Switch>
            <Route exact path="/" component={Home} />
              <Route exact path="/components/Home" component={Home} />
              <Route exact path="/components/Workout" component={Workout} />
              <Route exact path="/components/PostWorkout" component={PostWorkout} />
            </Switch>
          </div>
        </Router>
    </div>
  );
}

export default withAuthenticator(App, true, [], null, MyTheme);
