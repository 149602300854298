import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify'
import '../App.css';
import {Auth} from 'aws-amplify';
import moment from 'moment';


function PostWorkout(){

    const [level, setLevel] = useState(5)
    const [type, setType] = useState('orbitaltrainer')
    const [distance, setDistance] = useState('1.5')
    const [timeMinutes, setTimeMinutes] = useState(10)
    const [user, setUser] = useState('')
    const [post, setPost] = useState(false)

    const now = new Date()  
    const secondsSinceEpoch = Math.round(now.getTime())
    let workoutDateTime = secondsSinceEpoch
    console.log("EPOCHTIME",secondsSinceEpoch)
    
    function levelMenu() {
        return (
            <select onChange={e => setLevel(e.currentTarget.value)}>
            <option value="5">5</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            </select>
        );
    }

    function typeMenu() {
        return (
            <select onChange={e => setType(e.currentTarget.value)}>
            <option value="orbitaltrainer">Orbital Trainer</option>
            </select>
        );
    }


    async function checkUser() {
            let user = await Auth.currentAuthenticatedUser();  
            console.log("USER", user.username)
            setUser(user.username)
        }

    useEffect(() => {
        async function createWorkout() {
            let trainType = 'Orbital Trainer'
            if(post){
            if(type === 'orbitaltrainer'){
                trainType = 'Orbital Trainer'
            }    
            let PK = type + '-' + workoutDateTime
            let myInit = {
                body:{
                   "Action": "createWorkout",
                   "PK": user,
                   "SK": PK,
                   "level": level,
                   "distance": distance,
                   "timeMinutes": timeMinutes,
                   "type": trainType,
                   "dateTime": workoutDateTime,
                }
            }
            const apiData = await API.post ('staffordvilleapi', '/workouts', myInit)
            console.log("RESPONSE",apiData)
            setPost(false)
            }
        }
        checkUser();
        createWorkout()
    },[post])

        return (
        <div>

            <p><button className="butt1" type="button" onClick={() => setPost(true)} >Post Workout</button></p>
            <table className="gridwager">
                <tr><td><input type="text" value="mark" onChange={event => setUser(event.target.value)} /></td></tr>
                <tr><td>WorkoutDateTime:</td><td className="gridwagerleft"></td></tr>
                <tr><td>Level:</td><td className="gridwagerleft">{levelMenu()}</td></tr>
                <tr><td>Type:</td><td className="gridwagerleft">{typeMenu()}</td></tr>
                <tr><td>TimeMinutes:</td><td className="gridwagerleft"><input type="text" value={timeMinutes} onChange={event => setTimeMinutes(event.target.value)} /></td></tr>
                <tr><td>Distance:</td><td className="gridwagerleft"><input type="text" value={distance} onChange={event => setDistance(event.target.value)} /></td></tr>
            </table>  
                
        </div>
        )
}
export default PostWorkout